/*Header formatting */
.header-bar {
    display: flex;
    width: 98%;
    height:45px;
    padding:0.25% 1%;
    background-color: #68368B;
    color: #FFFFFF;
    box-shadow: 0 2px 35px #777777;
}

.header-app {
    width:13%;
    text-align: left;
}

.header-app-logo {
    height:100%;
    background-color: #FFFFFFCE;
    padding:3px 3px 3px 5px;
    border-radius: 4px;
}

.app-title {
    width:72%;
    text-align: center;
    font-size:1.25rem;
    font-weight: bold;
}
.app-login {
    width:15%;
    text-align: right;
    font-size:0.95rem;
}

.h-float {
    margin-left: auto;
    margin-right:auto;
}

.v-float {
    margin-top:auto;
    margin-bottom: auto;
}

.button-logout {
    background-color:#ebd9fcb4;
    border-radius: 4px;
    padding:6px 20px;
    font-weight: bold;
    color:#68368B;
    border: solid 1px #ffffffef;
}

.button-logout:hover {
    background-color:#ffffff;
    cursor: pointer;
    box-shadow: 0 0 1px #bb6be0a6;
}

/* Page contents */
.page {
    min-height:89%;
    width: 98%;
    margin-right:auto;
    margin-left:auto;
    padding:1%;
}

/* Login page formatting */
.modal-block {
    background-color: #D7D0D0C9;
    width: 100%;
    height:100%;
    overflow: auto;
    position: fixed;
    left:0;
    top:0;
    z-index: 5;
}

.modal-block-back-tall {
    width: 100%;
    height:fit-content;
    left:0;
    top:0;
    z-index: 25;
    margin-bottom: 5%;
}

.modal-block-back-short 
{
    width:500px;
    box-shadow: 0 2px 35px #777777;
    margin-top: 15%;
    margin-left:auto;
    margin-right: auto;
    z-index: 100;
}

.modal-page 
{
    width:90%;
    margin-left:auto;
    margin-right:auto;
    height:100%;
    box-shadow: 0 2px 35px #777777;
    margin-top: 5%;
}

.modal-header {
    display: flex;
    color: #FFFFFF;
    font-weight: bold;
    text-align: left;
    font-size: 1.5rem;
    letter-spacing: 0.08rem;
    border-radius:4px 4px 0 0;
}

.modal-header-small {
    padding:1%;
    width:98%;
}

.modal-header-large {
    height:15%;
    padding:2% 4%;
    width:92%;
}

.modal-header-regular {
    background-color: #68368B;
    border: solid 1px #68368B;
}

.modal-header-error {
    background-color: #b31c1c;
    border: solid 1px #b31c1c;
}

.modal-close > button {
    padding: 8px 15px;
    font-size:1.25rem;
    text-align: center;
    cursor: pointer;
    background-color: #d9b8fa;
    color:#000000;
    border-radius: 4px;
    border: solid 1px #b1b1b1;
    font-weight: bold;
}

.modal-close-red > button {
    background-color: #fab8b8;
    color:#000000;
    border: solid 1px #b1b1b1;
}

.modal-close-purple > button {
    background-color: #d9b8fa;
    color:#000000;
    border: solid 1px #b1b1b1;
}

.modal-body {
    width: 96%;
    height:100%;
    padding:2%;
    border: solid 1px #CFCFCF;
    color: #FFFFFF;
    border-radius:0 0 4px 4px;
}

.modal-body {
    background-color: #fafafa;
    color:#000000;
    font-size: 1.15rem;
}

.modal-row {
    padding:4%;
    width: 90%;
    display: flex;
}

.modal-row-border {
    padding:2%;
    margin: 0.5% 3% 1%;
    border-bottom: solid 1px #d9b8fa;
}

.modal-row-header {
    padding:0 0.5%;
    width: 20%;
    font-weight: bold;
    font-size:0.9rem;
}

.modal-row-data {
    padding:0.5%;
    width: 100%;
    display: flex;
    font-size:0.9rem;
}

.login-row-icon {
    width:15%;
    color:#777777;
    text-align: center;
    font-size:1.5rem;
}

.login-row-icon > svg:hover {
    color: #b1b1b1;
}

.login-row-input {
    width:85%;
    display: block;
    text-align: left;
}

.login-row-input > div > label {
    font-size:0.85rem;
    color: #585858;
    font-weight: bold;
}

.login-row-input > div > input {
    font-size:1.15rem;
    background-color: #dddbdb;
    border: solid 1px #b1b1b1;
    padding:5px;
    border-radius: 4px;
    width:80%;
}

.modal-row-input > input:hover, .modal-row-input > input:focus {
    font-size:1.15rem;
    background-color: #f3e7a3;
    border: solid 1px #68368B;
    cursor: pointer;
}

.modal-row-button {
    text-align: center;
}

.modal-row-button > button {
    padding: 2% 8%;
    font-size:1.25rem;
    text-align: center;
    background-color: #68368B;
    color:#EFEFEF;
    border-radius: 4px;
    border: solid 1px #b1b1b1;
    font-weight: bold;
}

.modal-row-button > button:hover {
    background-color: #d9b8fa;
    color:#161616;
    border: solid 1px #2b2b2b;
    cursor: pointer;
    box-shadow: 0 2px 35px #777777;
}

/* Loading animation styling */
.loading {
    background-color: #D7D0D0C9;
    width: 100%;
    height:100%;
    overflow: auto;
    position: fixed;
    left:0;
    top:0;
    z-index: 5;
}

.loading-container {
    display: block;
    margin: auto;
    border: 20px solid #d9b8fa;
    border-radius: 50%;
    border-top: 20px solid #68368B;
    width: 200px;
    height: 200px;
    animation: spinner 4s linear infinite;
    margin-top: 25%;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Search page */
.search-bar {
    width:90%;
    padding:1.75% 1%;
    display: flex;
}

.search-bar-reg {
    padding:1.75% 1%;
}

.search-bar-filter {
    padding:1% 1%;
}

.search-bar-title {
    padding:1% 1% 0;
}

.dropdown-spacing {
    padding:auto 10px;
}

.dropdown-label {
    color:#68368B;
    font-weight: bold;
    font-size:1.2rem;
    padding:5px 0;
    margin-top:auto;
    margin-bottom: auto;
    text-align: center;
}

.search-bar > label {
    font-size:0.7rem;
    position: absolute;
    background-color: #FFFFFF;
    margin-left:10px;
    margin-top:-4px;
    padding: 0 4px;
}

.search-bar-input {
    width:60%;
    padding:16px 0.75%;
    font-size:1.15rem;
    border: solid 1px #d9b8fa;
    border-radius: 4px;
}

.search-bar-error {
    width:60%;
    padding:16px 0.75%;
    font-size:1.15rem;
    border: solid 1px #b31c1c;
    border-radius: 4px;
}

.search-bar-error-message {
    margin-left:5%;
    margin-top:-20px;
    font-size:0.8rem;
    position: absolute;
    background-color: #FFFFFF;
    padding: 0 4px;
    color:#b31c1c;
    font-weight: bold;
}

.search-bar-input:focus, .search-bar-input:hover, .search-bar > label:focus {
    cursor: pointer;
    border-color: #68368B;
}

.search-bar-button {
    margin-left:4%;
    margin-right:4%;
    width:5%;
    padding:0.75%;
    font-size:1.25rem;
    border-radius: 4px;
    background-color: #68368B;
    color: #ebd9fc;
    border-color: #d9b8fa;
    font-weight: bold;
    
    box-shadow: 0 2px 35px #777777;
}

.search-bar-button:hover, .modal-close-purple > button:hover {
    background-color:#ebd9fcb4;
    color:#68368B;
    border: solid 1px #d9b8fa;
    cursor: pointer;
}

.modal-close-red > button:hover {
    background-color:#fcd9d9b4;
    color:#771818;
    border: solid 1px #fab8b8;
    cursor: pointer;
}

/* Search slider */
.search-bar-slider {
    width:20%;
    display:flex;
    margin-left:3%;
    margin-right:3%;
}

.search-bar-slider-label {
    color:#68368B;
    font-weight: bold;
    font-size:0.9rem;
    padding:5px 10px;
    margin-top:auto;
    margin-bottom: auto;
    text-align: center;
}

  .switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 40px;
    margin-top:auto;
    margin-bottom: auto;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 1px #d9b8fa;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: 4px;
    bottom: 4px;
    background-color:#68368B;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #d9b8fa;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #d9b8fa;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/* Search Results formatting */
.results {
    width:90%;
    padding:1.75% 1%;
}

.div-row {
    width: 96%;
    padding:1.25% 2%;
    display: flex;
    font-size: 0.95rem;
    border-radius: 2px;
}

.div-row-header {
    background-color: #68368B;
    color: #ede4f7;
    border: solid 1px #68368B;
    font-weight: bold;
}

.div-row-data {
    background-color: #ede4f7;
    border: solid 1px #d9b8fa;
    font-size: 0.95rem;
}

.div-row-data:hover {
    background-color: #d9b8fa;
    cursor: pointer;
    border: solid 1px #ede4f7;
}

.div-row-data:hover input {
    border: solid 1px #808080;
}

.format-left {
    text-align: left;
}

.format-center {
    text-align: center;
}

.format-bold {
    font-weight: bold;
}

.format-med {
    font-size: 1.15rem;
}

/* display */
.flex {
    display: flex;
}

/* Widths */
.width-05 {
    width: 5%;
}

.width-10 {
    width: 10%;
}

.width-12 {
    width: 12%;
}

.width-13 {
    width: 13%;
}

.width-15 {
    width: 15%;
}

.width-20 {
    width: 20%;
}

.width-25 {
    width: 25%;
}

.width-30 {
    width: 30%;
}

.width-35 {
    width: 35%;
}

.width-40 {
    width: 40%;
}

.width-50 {
    width: 50%;
}

.width-60 {
    width: 60%;
}

.width-80 {
    width: 80%;
}

.width-95 {
    width: 95%;
}

.width-100 {
    width: 100%;
}

/* Route, stop formatting */
.search-device-icon {
    width:50px;
    height: 39px;
    color:#d9b8fa;
    text-align: center;
    font-size:1.5rem;
    border-radius: 4px;
    border: solid 1px #d9b8fa;
    background-color: #68368B;
    margin-left: 5px;
}

.search-device-icon:hover {
    cursor: pointer;
    color:#68368B;
    background-color: #d9b8fa;
    border: solid 1px #68368B;
    box-shadow: 0 2px 35px #777777;
}

.stop-input, .stop-input-icon {
    width:fit-content;
    padding:10px 15px;
    font-size:0.9rem;
    border-radius: 3px;
    border: solid 1px #d9b8fa;
}

.stop-input-icon {
    width:300px;
}

.stop-input-input {
    width:fit-content;
}

.stop-input:not([disabled]) {
    background-color: #f7f7f7;
}

.stop-input:is([disabled]) {
    background-color: #e6e5e5;
    cursor: not-allowed;
}

.stop-input:not([disabled]):hover, .stop-input:not([disabled]):focus {
    background-color: #FFFFFF;
    border: solid 1px #68368B;
    cursor: pointer;
}

.stop-actions {
    width:96%;
    padding:2%;
}

.stop-action {
    padding:5% 5% 5% 15%;
    font-weight: bold;
    font-size: 1.15rem;
    color:#68368B;
    text-align: left;
    display: flex;
}

.stop-action-label {
    padding-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.stop-action-label-small {
    padding-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 0.95rem;
}

.stop-action-input {
    height:25px;
    width: 25px;
    border:solid 1px #d9b8fa;
}

.stop-action-input:hover {
    border:solid 1px #68368B;
}

.popup {
    position: relative;
    border-top: solid 2px #68368B;
}

.popup-block {
    border: solid 2px #68368B;
    background-color: #FFFFFF;
    position: absolute;
    width:300px;
    top:2.5rem;
    right:1rem;
    border-radius: 5px;
    padding: 0.5% 1%;
    box-shadow: 0 2px 35px #777777;
    z-index: 5;
    height: fit-content;
}


.popup-header {
    font-weight: bold;
    background-color: #d9b8fa;
    font-size:1.15rem;
    padding:5%;
    display: flex;
    border-radius:  4px 4px 0 0;
}

.popup-body {
    font-size:0.85rem;
    padding:3%;
    border-radius: 0 0 4px 4px;
}

.popup-text {
    font-size:0.85rem;
    padding:1% 6% 4%;
}

/* Footer formatting */
.footer-bar {
    margin-right:auto;
    margin-left:auto;
    display: flex;
    width: 98%;
    height:25px;
    padding:0.25% 0;
    border-top: solid 1px #68368B;
    color: #68368B;
}

.footer-user {
    width:20%;
    text-align: right;
    padding: 0 2%;
    font-size: 0.85rem;
    font-style: italic;
}